.chat-app {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height of the viewport */
  width: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.message-list-container {
  flex: 1; /* Take up remaining space */
  overflow-y: auto; /* Allow scrolling for messages */
  padding: 10px;
  background-color: #f9f9f9;
}

.message-input-container {
  border-top: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
}
